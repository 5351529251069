import React from 'react';
import { Spoiler, Table } from '@mantine/core';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2023/breast-feeding/1.jpg';
import blogd2 from '../../assets/images/2023/breast-feeding/2.jpg';
import blogd3 from '../../assets/images/2023/breast-feeding/3.jpg';
import blogd4 from '../../assets/images/2023/breast-feeding/4.jpg';
import blogd5 from '../../assets/images/2023/breast-feeding/5.jpg';
import blogd6 from '../../assets/images/2023/breast-feeding/6.jpg';
import blogd7 from '../../assets/images/2023/breast-feeding/7.jpg';
import blogd8 from '../../assets/images/2023/breast-feeding/8.jpg';
import blogd9 from '../../assets/images/2023/breast-feeding/9.jpg';
import blogd10 from '../../assets/images/2023/breast-feeding/11.jpg';
import blogd11 from '../../assets/images/2023/breast-feeding/10.jpg';
import blogd12 from '../../assets/images/2023/breast-feeding/12.jpg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  const subs = [
    {
      date: 'Tuesday, 1st August',
      activities:
        'Webiner',
      time:'@3:00pm',      
      },
    {
      date: 'Wednesday, 2nd August, 2023',
      activities:
        'Awareness Creation in LUTH',
      time:'@ 8:30am', 
      activities: 'Presentation by NANPAN LUTH @ Nurse Seminar Room',
      time: '@11:00am',
    },
    {
      date: 'Thursday, 3rd August',
      activities:
        'Train the trainers on BFHI @ Paediatrics Department',
      time:'@9:00am - 2:00pm', 
    },
    {
      date: 'Friday, 4th August',
      activities:
        'Outreach Program at Serikis Compound, Haruna Street, Idi-Araba',
      time:'@9:00am - 12:00noon', 
      activities:
        'Outreach Program at Serikis Compound, Haruna Street, Idi-Araba',
      time:'@1:00pm', 
    },
    {
      date: 'Sunday, 6th August',
      activities:
        'Health Talk @ LUTH/CMUL Chaplaincy, Idi-Araba',
      time:'@9:30am', 
    },
  ];

  const subRows = subs.map((element) => (
    <tr key={element.date}>
      <td>{element.date}</td>
      <td>{element.activities}</td>
      <td>{element.time}</td>
    </tr>
  ));
  
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  Lagos University Hospital celebrates World Breastfeeding Week
                  (WBW)
                </h2>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  The global World breast feeding Week was done in Lagos
                  University Hospital. World Breastfeeding Week (WBW) is a
                  global campaign to raise awareness and galvanise action on
                  themes related to breastfeeding.
                  <br />
                  WBW is celebrated every 1-7 August in commemoration of the
                  1990 Innocenti Declaration. WBW started in 1992, with annual
                  themes including healthcare systems, women and work, the
                  International Code of Marketing of Breastmilk Substitutes,
                  community support, ecology, economy, science, education and
                  human rights. Since 2016, WBW is aligned with the Sustainable
                  Development Goals (SDGs). In 2018, a World Health Assembly
                  resolution endorsed WBW as an important breastfeeding
                  promotion strategy.
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Consultative Services Ongoing at the Outreach
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  This years' event was done by Baby Friendly Hospital
                  Initiative, Lagos University Teaching Hospital Chapter with
                  the theme{' '}
                  <strong>
                    ENABLING BREASTFEEDING: Making a difference for working
                    parent
                  </strong>
                  . It was packed with Activities which would span from tuesday
                  1st of August to Sunday 6th of August. The program of
                  Activities were;
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  attendees at the seminar
                </span>

                {/* <table style='width:100%'>
                  <tr>
                    <th>Date</th>
                    <th>Activities</th>
                    <th>Venue</th>
                  </tr>
                  <tr>
                    <td>Tuesday, 1st August</td>
                    <td>Webinar</td>
                    <td>@3:00pm</td>
                  </tr>
                  <tr>
                    <td>Wednesday, 2nd August, 2023</td>
                    <td>Awareness Creation in LUTH
                        <br />
                        Presentation by NANPAN LUTH @ Nurse's Seminar Room
                    </td>
                    <td>@ 8:30am
                        <br />
                        @11:00am
                    </td>
                  </tr>
                  <tr>
                    <td>Thursday, 3rd August</td>
                    <td>Train the trainers on BFHI @ Paediatrics Department</td>
                    <td>@9:00am - 2:00pm</td>
                  </tr>
                  <tr>
                    <td>Friday, 4th August</td>
                    <td>Outreach Program at Seriki's Compound, Haruna Street, Idi-Araba</td>
                    <td>@9:00am - 12:00noon</td>
                  </tr>
                  <tr>
                    <td>Friday, 4th August</td>
                    <td>Outreach Program at Seriki's Compound, Haruna Street, Idi-Araba</td>
                    <td>@1:00pm</td>
                  </tr>
                  <tr>
                    <td>Sunday, 6th August</td>
                    <td>Health Talk @ LUTH/CMUL Chaplaincy, Idi-Araba</td>
                    <td>@9:30am</td>
                  </tr>
                </table> */}
                {/* <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  She said that NSOANP had to do with providing affordable,
                  accessible and safe surgery to Nigerians that need it as part
                  of Universal Health Coverage. “This is as a result of World
                  Health Assembly Resolution in 2015 which mandated Member
                  States to include basic and Surgical Care as part of Universal
                  coverage in the country. “Federal Ministry of Health produced
                  the plan and most of the implementation takes place at the
                  State level. “That is why we started the dissemination to the
                  36 States and the Federal Capital Territory after approval was
                  given at the National Council of Health,”. She said that World
                  Health Assembly Resolution 68:15 says Surgical, Obstetrics,
                  Anesthesia and Nursing care are important components of
                  Universal Health Coverage, therefore, Surgeries are fully
                  integrated into Healthcare Services.
                </p> */}
                <Table fontSize='md' striped>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Activities</th>
                          <th>Time</th>
                        </tr>
                      </thead>
                      <tbody>{subRows}</tbody>
                    </Table>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Sections of the Seminar
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd8} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd9} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Sections of the Seminar
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd10} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd11} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd12} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  More pictures
                </span>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'></a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Lagos University Hospital World Breastfeeding Week (WBW)  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
